import Vue from 'vue';
import App from './App.vue';
import {BootstrapVue} from 'bootstrap-vue';

import router from '@/confs/router';
import hljs from 'highlight.js';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';

import 'alertifyjs/build/css/alertify.min.css';
import 'alertifyjs/build/css/themes/bootstrap.css';
import 'highlight.js/styles/stackoverflow-dark.css';
import './styles/entry.scss';

import '@/confs/icons';


Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.use(hljs.vuePlugin);
Vue.use(BootstrapVue);


new Vue({
    router,
    render: h => h(App)
}).$mount('#app');

