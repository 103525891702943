<template>
  <section class="content">
    <Header v-if="unlocked"></Header>
    <router-view/>
  </section>
</template>

<script>
import Header from "@/components/includes/Header";
import Cookies from "js-cookie";

export default {
  name: 'PageContent',
  components: {Header},
  data() {
    return {
      unlocked: false,
      str: ""
    }
  }, methods: {
    logout() {
      Cookies.remove("unlocked")
      this.unlocked = false
      this.$router.push("/")
    },
    onKeyPress(e) {
      if (this.unlocked) {
        return
      }
      this.str += e.key
      if (this.str.length > 5) {
        this.str = this.str.substring(this.str.length - 6)
      }
      if (this.str === "bipbipboop") {
        this.unlocked = true
        Cookies.set('unlocked', true)
      }
    },
  },
  mounted() {
    window.addEventListener('keyup', this.onKeyPress);
    this.unlocked = Cookies.get('unlocked')
  }
};
</script>
