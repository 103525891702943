<template>
  <div class="main">
    <PageContent></PageContent>
  </div>
</template>

<script>
import PageContent from "@/components/PageContent";

export default {
  name: 'App',
  components: {PageContent}
};
</script>

<style lang="sass">
@import '../node_modules/typeface-roboto/index.css'
</style>