<template>
  <div id="cv">
    <div id="actions">
      <a id="hiddenDl" href=""></a>
      <button class="dlPdf" v-on:click="downloadPdf">
        <font-awesome-icon icon="file-pdf" size="2x"></font-awesome-icon>
      </button>
      <button class="dlMd" v-on:click="downloadMd">
        <font-awesome-icon :icon="['fab', 'markdown']" size="2x"></font-awesome-icon>
      </button>
      <button class="faq" v-on:click="goFaq">
        <font-awesome-icon icon="question-circle" size="2x"></font-awesome-icon>
      </button>
    </div>
    <!-- Don't worry; i won't do that once you hire me.
     I only allow this to myself because there cannot be
     consequences that would bother me -->
    <UnsafeHTML v-bind:template="html"></UnsafeHTML>
  </div>
</template>

<script>
import showdown from 'showdown'
import cv from '@/markdowns/CV.md'
import UnsafeHTML from "@/components/includes/UnsafeHTML";


export default {
  name: 'Home',
  components: {UnsafeHTML}, data() {
    return {
      md: "**Not loaded**",
      html: "<strong>Not loaded</strong>",
      data: null,
      conf: {
        asideIndexes: [2, 3]
      }
    }
  },
  methods: {
    downloadPdf() {
      document.getElementById('actions').classList.add("hidden")
      window.print();
      document.getElementById('actions').classList.remove("hidden")
    },
    downloadMd() {
      window.URL = window.URL || window.webkitURL;
      var bt = document.getElementById('hiddenDl')
      bt.setAttribute('href', window.URL.createObjectURL(new Blob([this.md], {type: 'text/plain'})));
      bt.setAttribute('download', 'CV BANCAREL Valentin.md');
      bt.click()
    },
    goFaq() {
      this.$router.push('faq')
    },
    postUpdate() {
      let strongs = document.getElementsByTagName("strong");
      for (let i = 0; i < strongs.length; i++) {
        const strong = strongs[i];
        if (strong.parentElement.childNodes.length !== 1) {
          strong.classList.add("validated")
        }
      }
    },
    prepareHtml(html) {
      const regex = /<hr *\/?>/gi;
      html =
          "<article>" + html
              .replace(/E-mail: *([a-zA-Z0-9.@-_]*)/, "E-mail: <a href='mailto:$1'>$1</a>")
              .replace(/Téléphone: *([0-9]*)/, "Téléphone: <a href='tel:$1'>$1</a>")
              .replace("Adresse:", "<font-awesome-icon icon=\"home\"></font-awesome-icon>")
              .replace("Téléphone:", "<font-awesome-icon icon=\"phone\"></font-awesome-icon>")
              .replace("E-mail:", "<font-awesome-icon icon=\"at\"></font-awesome-icon>")
              .replace("LinkedIn:", "<font-awesome-icon :icon=\"['fab', 'linkedin']\"></font-awesome-icon>")
              .replace("Website:", "<font-awesome-icon icon=\"globe\"></font-awesome-icon>")
              .replaceAll(" - Lycée", "<br>Lycée")
              .replaceAll("Expèriences professionnelles", "<font-awesome-icon icon=\"user\"></font-awesome-icon> Expèriences professionnelles")
              .replaceAll("Projet personnels", "<font-awesome-icon icon=\"heart\"></font-awesome-icon> Projet personnels")
              .replaceAll(regex, "</article><article>")
          + "</article>"

      let newHtml = document.createElement('div');
      newHtml.id = "converted-md"
      newHtml.classList.add("cv-content")
      newHtml.innerHTML = html

      let aside = document.createElement('aside')
      newHtml.insertBefore(aside, newHtml.firstChild);

      let articles = newHtml.getElementsByTagName("article");
      for (let i = 0; i < articles.length; i++) {
        if (this.conf.asideIndexes.indexOf(i + 1) > -1) {
          aside.appendChild(articles[i])
        }
      }

      return newHtml.outerHTML
    }
  },
  mounted() {
    window.onresize = this.postUpdate;
    fetch(cv)
        .then(response => response.text())
        .then(md => this.md = md)
  },
  watch: {
    md: {
      immediate: true,
      handler() {
        this.html = this.prepareHtml(new showdown.Converter().makeHtml(this.md))
        setTimeout(this.postUpdate, 500)
      }
    }
  }
};
</script>
