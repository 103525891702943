<template>
  <nav>
    <ul>
      <li>
        <router-link to="/speech">Generic</router-link>
      </li>
      <li>
        <router-link to="/speech/sword">[Conserto] sword</router-link>
      </li>
    </ul>
    <button v-on:click="logout">logout</button>
  </nav>
</template>

<script>

export default {
  name: 'Header',
  methods: {
    logout() {
      this.$parent.logout()

    }
  }
};
</script>