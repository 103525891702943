import {library} from '@fortawesome/fontawesome-svg-core';
import {faLinkedin, faMarkdown} from '@fortawesome/free-brands-svg-icons/';
import {
    faAt,
    faDownload,
    faFilePdf,
    faGlobe,
    faHeart,
    faHome,
    faPhone,
    faQuestionCircle,
    faUser
} from '@fortawesome/free-solid-svg-icons/';
// import {faFontAwesome} from '@fortawesome/free-brands-svg-icons/faDiscord';

library.add(faAt, faHome, faQuestionCircle, faPhone, faGlobe, faLinkedin, faMarkdown, faFilePdf, faUser, faHeart, faDownload);