<template>
  <UnsafeHTML v-bind:template="html"></UnsafeHTML>
</template>

<script>
import showdown from 'showdown'
import UnsafeHTML from "@/components/includes/UnsafeHTML";

export default {
  name: 'Home',
  components: {UnsafeHTML}, data() {
    return {
      html: "<strong>Not loaded</strong>"
    }
  }, mounted() {
    var fileName = this.$attrs.file
    // var file = require(fileName)

    fetch(fileName)
        .then(response => response.text())
        .then(text => new showdown.Converter().makeHtml(text))
        .then(html => "<article>" + html + "</article>")
        .then(html => this.html = html)
  }
};
</script>

<style lang="scss" scoped>
</style>