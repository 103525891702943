import VueRouter from "vue-router";
import Vue from "vue";
import Home from "@/components/pages/Home";
import FAQ from "@/components/pages/FAQ";
import Speech from "@/components/pages/Speech";
import Cookies from "js-cookie";

import speechGen from "@/markdowns/speeches/generic.md"
import speechSword from "@/markdowns/speeches/sword.md"


Vue.use(VueRouter);


const router = new VueRouter({
    routes: [
        {path: '/', component: Home},
        {path: '/faq', component: FAQ},
        {path: '/speech', component: Speech, props: {file: speechGen}},
        {path: '/speech/sword', component: Speech, props: {file: speechSword}},
    ]
});

router.beforeEach((to, from, next) => {
    if (to.path.startsWith("/speech")) {
        let truffe = JSON.parse(Cookies.get('truffe')) || false;
        if (!truffe) {
            return next(false);
        }
    }
    return next();
});

export default router;