<template>
  <aside>
    <nav>
      <ul>
        <li>
          <router-link to="/">CV</router-link>
        </li>
        <li>
          <router-link to="/faq">FAQ</router-link>
        </li>
      </ul>
    </nav>
  </aside>
</template>

<script>

export default {
  name: 'Nav',
};
</script>