<template>
  <div id="faq">
    <Nav></Nav>
    <article>
      <h2>Pourquoi un design aussi basique ?</h2>
      <div>
        <p>En effet c'est pas le site de l'année... Mais je souhaitais garder mon CV au format markdown pour
          l'éditer facilement; je le converti ensuite en HTML via une librairie open source.
          Par conséquent je n'ai pas de controle sur le HTML généré à moins de coder ma propre conversion markdown >
          HTML
          et ce n'est vraiment pas dans mes priorités.</p>
      </div>
    </article>
    <article>
      <h2>Est-ce possible d'avoir le CV au format word ?</h2>
      <div>
        <p>Non. PDF est un format destiné principalement à la lecture de document. Word quand a lui a l'edition de
          document.
          Je ne rends pas mon CV publique afin que quiconque l'édite mais uniquement dans un but de prise d'information;
          par conséquent
          je n'arrive pas à imaginer un seul use case ou quelqu'un pourrai avoir besoin d'éditer mon CV dans un but
          m'étant bénéfique.
          Si jamais je me trompe vous toujours me contacter via les informations présentent sur le CV.</p>
      </div>
    </article>
  </div>
</template>

<script>

import Nav from "@/components/includes/Nav";

export default {
  name: 'FAQ',
  components: {Nav}, data() {
    return {}
  },
  methods: {
    downloadPdf() {
      window.print();
    },
  },
  mounted() {
  }
};
</script>