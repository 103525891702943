<script>
import Vue from "vue";

//https://jsfiddle.net/Linusborg/1zdzu7k1/
export default {
  name: 'UnsafeHTML',
  methods: {},
  props: ['template'],
  data() {
    return {
      templateRender: null,
    };
  },
  render() {
    return this.templateRender();
  },
  watch: {
    template: {
      immediate: true,
      handler() {
        console.log(this.template)
        var res = Vue.compile(this.template);

        this.templateRender = res.render;

        // staticRenderFns belong into $options,
        // appearantly
        this.$options.staticRenderFns = []

        // clean the cache of static elements
        // this is a cache with the results from the staticRenderFns
        this._staticTrees = []

        // Fill it with the new staticRenderFns
        for (var i in res.staticRenderFns) {
          //staticRenderFns.push(res.staticRenderFns[i]);
          this.$options.staticRenderFns.push(res.staticRenderFns[i])
        }
      }
    }
  }
};
</script>